import { Inject, Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ENVIRONMENT_TOKEN, Environment } from '@shared/models/environment.model';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AuthService {
  /** observable that will emit a boolean flag specifying if the user is authenticated or not */
  public isAuthenticated$ = new BehaviorSubject<boolean>(false);

  /** Current value of isAuthenticated flag */
  public get isAuthenticated(): boolean {
    return this.isAuthenticated$.getValue();
  }

  constructor(
    @Inject(ENVIRONMENT_TOKEN) private env: Environment,
    private jwtHelper: JwtHelperService
  ) {}

  public checkIfAuthenticated(token: string) {
    if (!token || this.jwtHelper.isTokenExpired(token)) {
      this.isAuthenticated$.next(false);
      return;
    }

    const tokenPayload = this.jwtHelper.decodeToken(token);
    if (tokenPayload['iss'] !== this.env.jwtTokenIssuer) {
      this.isAuthenticated$.next(false);
      return;
    }

    window.sessionStorage.setItem('access_token', token);
    window.sessionStorage.setItem('zendesk_enabled', tokenPayload['ZendeskEnabled']);
    this.isAuthenticated$.next(true);
  }
}
