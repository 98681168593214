import { InjectionToken } from '@angular/core';

export const ENVIRONMENT_TOKEN = new InjectionToken<Environment>('APP_ENVIRONMENT');

export interface Environment {
  adobeAnalyticsSourceURL: string;
  hereMapsApiKey: string;
  jwtTokenIssuer: string;
  myScaniaSPUrl: string;
  production: boolean;
  serviceBaseServicePlanning: string;
  serviceBaseServicePlanningDomain: string;
}
