/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NoLongerValidComponent } from '@booking-proposal/components/no-longer-valid/no-longer-valid.component';
import { NoNetworkComponent } from '@shared/components/no-network/no-network.component';
import { AuthGuardService as AuthGuard } from './auth/auth-guard.service';

const routes: Routes = [
  {
    path: 'booking-proposal/no-longer-valid',
    component: NoLongerValidComponent
  },
  {
    path: 'booking-proposal/playground',
    loadChildren: () => import('./booking-proposal/booking-proposal.module').then((m) => m.BookingProposalModule)
  },
  {
    path: 'booking-proposal/:externalBookingReference',
    loadChildren: () => import('./booking-proposal/booking-proposal.module').then((m) => m.BookingProposalModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'booking-proposal-info/:workOrderId',
    loadChildren: () => import('./booking-proposal/booking-proposal.module').then((m) => m.BookingProposalModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'no-network',
    component: NoNetworkComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
