import { JOB_TYPE } from '@booking-proposal/model/booking-proposal-job.model';
import { BookingProposal, BookingSubStatus } from '@booking-proposal/model/booking-proposal.model';

export const mockBookingProposal: BookingProposal = {
  equipment: {
    chassisNumber: '5345792',
    registrationNumber: '95-BDS-4',
    alias: 'Digital Dealer 9',
    vin: 'YS2R6X20005345792'
  },
  bookingReference: '3e237c59-14a4-47c0-b319-eb177b1aac1f',
  bookingSubStatus: BookingSubStatus.PROPOSAL,
  jobs: [
    {
      header: 'Flexible maintenance',
      jobReference: 'df90c27a-6cd6-4182-a77b-86312b03d332',
      type: JOB_TYPE.MAINTENANCE
    },
    {
      header: 'Issue found when inspecting truck',
      jobReference: '00000000-0000-0000-0000-000000000000',
      type: JOB_TYPE.REPAIR
    },
    {
      header: 'Vehicle health check',
      jobReference: '00000000-0000-0000-0000-000000000000',
      type: JOB_TYPE.OTHER
    }
  ],
  dropOffTime: '2021-04-22T09:00:00Z',
  pickUpTime: '2021-04-22T12:00:00Z',
  messageFromWorkshop: 'Time for service on 216223',
  workshopInfo: {
    email: 'service.jordbro@scania.com',
    name: 'Scania Sverige AB / Jordbro',
    address: {
      street: 'Silovägen 14',
      city: 'Haninge',
      postalCode: '136 50'
    },
    location: {
      longitude: 18.110211,
      latitude: 59.15449
    },
    phone: '+46 (0) 10 706 62 10'
  }
};

export const mockBookingResponse = {
  data: mockBookingProposal,
  isSuccesful: true
};
