import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { JwtModule } from '@auth0/angular-jwt';
import { environment } from '@environments/environment';
import { TranslationUtilsModule } from '@myscania/translation-utils-angular';
import { NoInternetToastrComponent } from '@shared/components/no-internet-toastr/no-internet-toastr.component';
import { loadAdobeAnalytics } from '@shared/init/adobe-analytics-loader';
import { ENVIRONMENT_TOKEN } from '@shared/models/environment.model';
import { SharedModule } from '@shared/shared.module';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuardService } from './auth/auth-guard.service';
import { AuthService } from './auth/auth.service';

@NgModule({
  declarations: [AppComponent, NoInternetToastrComponent],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    SharedModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true
    }),
    TranslationUtilsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: () => window.sessionStorage.getItem('access_token'),
        allowedDomains: [environment.serviceBaseServicePlanningDomain]
      }
    })
  ],
  exports: [],
  providers: [
    AuthGuardService,
    AuthService,
    { provide: ENVIRONMENT_TOKEN, useValue: environment },
    { provide: APP_INITIALIZER, multi: true, useFactory: loadAdobeAnalytics, deps: [ENVIRONMENT_TOKEN] }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
