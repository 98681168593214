import { OverlayModule } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule, DecimalPipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CheckboxComponent } from '@shared/components/checkbox/checkbox.component';
import {
  ChecklistTableColumnDefDirective,
  ChecklistTableComponent
} from '@shared/components/checklist-table/checklist-table.component';
import { InformationComponent } from '@shared/components/information/information.component';
import { LoaderComponent } from '@shared/components/loader/loader.component';
import { MapComponent } from '@shared/components/map/map.component';
import { LocalizedDatePipe } from '@shared/pipes/localized-date/localized-date.pipe';
import { ButtonModule } from '@teamsp/components/button';
import { LOADER_COMPONENT_TOKEN, LoaderModule } from '@teamsp/components/loader';
import { GlobalErrorHandler } from './errors/error-handler';
import { BookingProposalPlaygroundInterceptor } from './http-interceptors/booking-proposal-playground-interceptor';

@NgModule({
  declarations: [
    CheckboxComponent,
    ChecklistTableColumnDefDirective,
    ChecklistTableComponent,
    InformationComponent,
    LoaderComponent,
    LocalizedDatePipe,
    MapComponent
  ],
  imports: [
    ButtonModule,
    CommonModule,
    FormsModule,
    LoaderModule,
    MatCardModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    NgbModule,
    NgSelectModule,
    OverlayModule,
    ReactiveFormsModule,
    RouterModule,
    ScrollingModule,
    TranslateModule
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: BookingProposalPlaygroundInterceptor, multi: true },
    { provide: LOADER_COMPONENT_TOKEN, useValue: LoaderComponent },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    DecimalPipe,
    TranslateService
  ],
  exports: [
    ButtonModule,
    CheckboxComponent,
    ChecklistTableColumnDefDirective,
    ChecklistTableComponent,
    InformationComponent,
    LoaderComponent,
    LoaderModule,
    LocalizedDatePipe,
    MapComponent,
    TranslateModule
  ]
})
export class SharedModule {}
