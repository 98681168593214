import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from '@shared/services/analytics/analytics.service';

@Component({
  selector: 'proposal-no-longer-valid',
  templateUrl: './no-longer-valid.component.html',
  styleUrls: ['./no-longer-valid.component.scss']
})
export class NoLongerValidComponent implements OnInit {
  constructor(private analyticsService: AnalyticsService) {}

  ngOnInit(): void {
    this.analyticsService.pushPageViewEvent('sp:external:invalid-proposal');
  }
}
