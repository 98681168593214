import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { mockBookingProposal } from "@booking-proposal/mocks/booking-proposal.mock";
import { ActivatedRoute, Router } from "@angular/router";

@Injectable()
export class BookingProposalPlaygroundInterceptor implements HttpInterceptor {
  constructor(private router: Router, private route: ActivatedRoute) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    if (this.router.routerState.snapshot.url.includes("playground")) {
      if(req.method === 'GET') {
        return of(new HttpResponse( { body: mockBookingProposal }));
      }

      if(req.method === 'POST') {
        if(this.route.snapshot.queryParams.displayProposalThankYou) {
          return of(new HttpResponse( { body: true }));
        }
      }
    }

    return next.handle(req);
  }
}
