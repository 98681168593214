// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { Environment } from '@shared/models/environment.model';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: Environment = {
  adobeAnalyticsSourceURL: 'https://assets.adobedtm.com/03cc06ac496b/bf19cf3d16f8/launch-dd9066290f47-staging.min.js',
  hereMapsApiKey: 'w80vUka5vOxeGmLl8ppK6IZ2UmmeUeGC7NAxPwyg6AQ',
  jwtTokenIssuer: 'sp-booking-proposal-test',
  myScaniaSPUrl: 'https://service-planning.test.cs.scania.com',
  production: false,
  serviceBaseServicePlanning: 'https://sp-frontendgateway-api.testapisp.cs.scania.com',
  serviceBaseServicePlanningDomain: 'sp-frontendgateway-api.testapisp.cs.scania.com'
};
