/* eslint-disable @typescript-eslint/naming-convention */
import {
  AfterContentInit,
  Component,
  ContentChildren,
  Directive,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { MatTable } from '@angular/material/table';

export interface ChecklistTableColumn {
  fieldName: string;
  title: string;
  size?: number;
}

export interface ChecklistTableRow {
  checked: boolean;
  disabled: boolean;
  removable: boolean;
  data: any;
}

@Directive({
  selector: '[checklistTableColumnDef]'
})
export class ChecklistTableColumnDefDirective {
  @Input('checklistTableColumnDef') targetColumn: string;
  constructor(public templateRef: TemplateRef<any>) {}
}

@Component({
  selector: 'checklist-table',
  templateUrl: './checklist-table.component.html',
  styleUrls: ['./checklist-table.component.scss']
})
export class ChecklistTableComponent implements OnInit, OnChanges, AfterContentInit {
  CHECKBOX_COLUMN = 'checkbox';
  REMOVE_BUTTON_COLUMN = 'removeButton';

  @Input() columns: ChecklistTableColumn[] = [];
  @Input() data: any[] = [];
  @Input() isChecklist = false;
  @Input() defaultCheckedData: any[] = [];
  @Input() disabledData: any[] = [];
  @Input() removableData: any[] = [];
  @Input() keyAccessor: (a: any) => any = (a) => a;
  /** emits an array of items selected from the specified data array
   * each time a selection change occurs */
  @Output() selectionChange: EventEmitter<any[]> = new EventEmitter();
  /** emits the item that has been removed */
  @Output() itemRemoved: EventEmitter<any> = new EventEmitter();
  /** holds a reference to the list of cell definitions specified as content children for this component */
  @ContentChildren(ChecklistTableColumnDefDirective) columnDefs: QueryList<ChecklistTableColumnDefDirective>;
  /** holds a reference to the MatTable */
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;

  columnDefsMap: Record<string, TemplateRef<any>> = {};
  dataSource: ChecklistTableRow[];
  displayedColumns: string[] = [];
  hasRemovableItems = false;
  constructor() {}

  emitSelection() {
    const itemsToEmit = this.dataSource.filter((tableItem) => tableItem.checked).map((tableItem) => tableItem.data);

    this.selectionChange.emit(itemsToEmit);
  }

  includesItemByRefference(items: any[], item: any): boolean {
    return !!items.find((other) => this.keyAccessor(other) === this.keyAccessor(item));
  }

  getDataSource(rows: any[] = []) {
    const checklistTableItems: ChecklistTableRow[] = rows.map((row) => {
      const checked = this.includesItemByRefference(this.defaultCheckedData, row);
      const disabled = this.includesItemByRefference(this.disabledData, row);
      const removable = this.includesItemByRefference(this.removableData, row);

      if (removable) {
        this.hasRemovableItems = true;
      }

      return { checked, disabled, removable, data: row };
    });

    return checklistTableItems;
  }

  getDisplayedColumns(columns: ChecklistTableColumn[] = []) {
    return [
      ...(this.isChecklist ? [this.CHECKBOX_COLUMN] : []),
      ...columns.map((column) => column.fieldName),
      ...(this.hasRemovableItems ? [this.REMOVE_BUTTON_COLUMN] : [])
    ];
  }

  resetDisabledItems(disabledData: any[] = []) {
    this.dataSource.forEach(
      (tableRow) => (tableRow.disabled = this.includesItemByRefference(disabledData, tableRow.data))
    );
  }

  resetCheckedItems(checkedByDefaultData: any[] = []) {
    this.dataSource.forEach(
      (tableRow) => (tableRow.checked = this.includesItemByRefference(checkedByDefaultData, tableRow.data))
    );
  }

  resetRemovableItems(removableData: any[] = []) {
    this.dataSource.forEach(
      (tableRow) => (tableRow.removable = this.includesItemByRefference(removableData, tableRow.data))
    );
    this.hasRemovableItems = removableData.length > 0;
    this.displayedColumns = this.getDisplayedColumns(this.columns);
  }

  toggleRow(row: ChecklistTableRow) {
    if (!this.isChecklist || row.disabled) {
      return;
    }
    row.checked = !row.checked;
    this.emitSelection();
  }

  removeRow(row: ChecklistTableRow) {
    this.itemRemoved.emit(row.data);
    this.data = this.data.filter((item) => this.keyAccessor(item) !== this.keyAccessor(row.data));
    this.dataSource = this.getDataSource(this.data);
    // if the removed row was checked the selection is updated and needs to be emitted
    if (row.checked) {
      this.emitSelection();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']?.currentValue) {
      this.dataSource = this.getDataSource(changes['data'].currentValue);
    }
    if (changes['columns']?.currentValue) {
      this.displayedColumns = this.getDisplayedColumns(changes['columns'].currentValue);
    }
    if (typeof changes['isChecklist']?.currentValue === 'boolean') {
      this.displayedColumns = this.getDisplayedColumns(this.columns);
    }
    if (changes['defaultCheckedData']?.currentValue) {
      this.resetCheckedItems(changes['defaultCheckedData'].currentValue);
    }
    if (changes['removableData']?.currentValue) {
      this.resetRemovableItems(changes['removableData'].currentValue);
    }
    if (changes['disabledData']?.currentValue) {
      this.resetDisabledItems(changes['disabledData'].currentValue);
    }
  }

  ngOnInit() {
    this.dataSource = this.getDataSource(this.data);
    this.displayedColumns = this.getDisplayedColumns(this.columns);
  }

  ngAfterContentInit() {
    // map the column definitions after the content children have been initialized
    this.columnDefs.forEach((columnDef) => {
      this.columnDefsMap[columnDef.targetColumn] = columnDef.templateRef;
    });
  }
}
