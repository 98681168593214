export interface BookingProposalJob {
  header: string;
  jobReference: string;
  type: string;
}

export enum JOB_TYPE {
  REPAIR = 'REPAIR',
  MAINTENANCE = 'MAINTENANCE',
  VHC = 'VHC',
  OTHER = 'OTHER'
}
