import { Injectable, InjectionToken } from '@angular/core';
import { Guid } from 'guid-typescript';

export type AnalyticsViewContext = string;

export const ANALYTICS_VIEW_CONTEXT = new InjectionToken<AnalyticsViewContext>('ANALYTICS_VIEW_CONTEXT', {
  providedIn: 'root',
  factory: () => 'sp:external:default-view'
});

export type AnalyticsEventData = Record<string, any>;

export interface DecoratedAnalyticsEventData extends AnalyticsEventData {
  session: {
    sessionReference: string;
    language: string;
    screenSize: {
      width: number;
      height: number;
    };
    userAgent: string;
  };
}

export interface AnalyticsPageEventData extends AnalyticsEventData {
  pageName: string;
}

export enum AnalyticsEvent {
  PAGE_VIEW = 'page_view',
  BOOKING_PROPOSAL_ACCEPT = 'booking_proposal_accept',
  SERVICE_PLANNING_REDIRECT = 'service_planning_redirect'
}

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
  private sessionReference: Guid;

  constructor() {
    this.sessionReference = Guid.create();
  }

  pushEvent(eventName: string, eventData: AnalyticsEventData = {}) {
    const decoratedEvent: DecoratedAnalyticsEventData = {
      ...eventData,
      session: {
        // append session data to the event
        screenSize: { width: window.innerWidth, height: window.innerHeight },
        sessionReference: this.sessionReference.toString(),
        language: window.navigator.language,
        userAgent: window.navigator.userAgent
      }
    };

    window.adobeDataLayer.push({
      event: eventName,
      // analytics team asked to change the `eventInfo` property to `pageInfo` for `page_view` events
      ...(eventName === AnalyticsEvent.PAGE_VIEW ? { pageInfo: decoratedEvent } : { eventInfo: decoratedEvent })
    });
  }

  pushPageViewEvent(pageName: string) {
    return this.pushEvent(AnalyticsEvent.PAGE_VIEW, { pageName });
  }
}
