import { ErrorHandler, Inject, Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { NoNetworkService } from '../services/no-network/no-network.service';
import { ToastrMessageService } from '../services/toastr-message/toastr-message.service';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  constructor(
    @Inject(Injector) private readonly injector: Injector,
    private translate: TranslateService,
    private noNetworkService: NoNetworkService
  ) {
    super();
  }

  handleError(error: Error) {
    let message =
      this.translate.instant('EttTillfälligtFelUppstod_VäntaEnStundOchFörsökIgen_OmProblemet') ||
      this.translate.instant('EttOhanteratFelUppstod');
    if (!this.noNetworkService.isNetworkAvailable()) {
      message = this.translate.instant('DuÄrOffline');
      this.toastr.warning(message);
    } else {
      this.toastr.error(message);
    }
    super.handleError(error);
  }

  private get toastr(): ToastrMessageService {
    return this.injector.get(ToastrMessageService);
  }
}
