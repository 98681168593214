/* eslint-disable @typescript-eslint/require-await, @typescript-eslint/no-empty-function */
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UpdateService } from '@shared/services/update/update.service';
import { filter, tap } from 'rxjs';
import { AuthService } from './auth/auth.service';

declare global {
  interface Window {
    signOut: any;
  }
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  environmentName = 'dev';
  canAccessZendeskWidget = false;

  constructor(
    private authService: AuthService,
    private translate: TranslateService,
    private updateService: UpdateService
  ) {}

  get cultureCode() {
    return this.translate.currentLang;
  }

  async ngOnInit(): Promise<void> {
    // start listening for new versions of the application
    this.updateService.listen();
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('en-GB');
    this.translate.use('en-GB');
    this.authService.isAuthenticated$
      .pipe(
        filter((isAuthenticated) => !!isAuthenticated),
        tap(() => {
          this.canAccessZendeskWidget = JSON.parse(sessionStorage.getItem('zendesk_enabled').toLocaleLowerCase());
        })
      )
      .subscribe();

    document.addEventListener('navbarLanguageSelected', (event: CustomEvent) => {
      this.translate.use(event.detail.culture);
    });
  }
}
