import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';

export type LocalizedDatePattern = 'shortDate' | 'mediumDate' | 'mediumDateTime' | string;
export type LocalizedDateInput = number | string | Date | dayjs.Dayjs;

export const DATE_FORMATS = {
  mediumDate: 'DD/MM/YYYY',
  mediumDateTime: 'DD/MM/YYYY HH:mm',
  shortDate: 'MM/YYYY'
};

@Pipe({
  name: 'localizedDate'
})
export class LocalizedDatePipe implements PipeTransform {
  transform(value: LocalizedDateInput, pattern: LocalizedDatePattern = 'mediumDate'): string {
    return value ? dayjs(value).format(DATE_FORMATS[pattern] || pattern) : '';
  }
}
